var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',[_c('el-form',{attrs:{"model":_vm.permitConfig,"label-width":"100px","inline":true}},[_c('el-col',{attrs:{"lg":9,"md":20}},[_c('el-form-item',{attrs:{"label":"AffiliateIds"}},[_c('group-select',{attrs:{"data-source":_vm.affiliatesGroupList,"multiple":"","reserve-keyword":"","loading":_vm.affiliateLoading,"clearable":"","filterable":"","placeholder":"请选择"},scopedSlots:_vm._u([{key:"option-item",fn:function({scope}){return [_c('div',{style:({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                })},[_c('span',[_vm._v(_vm._s(scope.label))]),_c('el-tag',{attrs:{"effect":"dark","hit":true,"type":scope.type,"size":"mini"}},[_vm._v(_vm._s(scope.tag))])],1)]}}]),model:{value:(_vm.affiliateIds),callback:function ($$v) {_vm.affiliateIds=$$v},expression:"affiliateIds"}})],1)],1),_c('el-col',{attrs:{"lg":8,"md":20}},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getDataList(1)}}},[_vm._v("Search")])],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.delPermit}},[_vm._v("Delete")])],1)],1)],1)],1),_c('el-row',[_c('el-form',{attrs:{"model":_vm.permitConfig,"label-width":"100px","inline":true}},[_c('el-col',{attrs:{"lg":7,"md":20}},[_c('el-form-item',{attrs:{"label":"AffiliateId"}},[_c('group-select',{attrs:{"data-source":_vm.affiliatesGroupList,"multiple":"","reserve-keyword":"","loading":_vm.affiliateLoading,"clearable":"","filterable":"","placeholder":"请选择"},scopedSlots:_vm._u([{key:"option-item",fn:function({scope}){return [_c('div',{style:({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                })},[_c('span',[_vm._v(_vm._s(scope.label))]),_c('el-tag',{attrs:{"effect":"dark","hit":true,"type":scope.type,"size":"mini"}},[_vm._v(_vm._s(scope.tag))])],1)]}}]),model:{value:(_vm.permitConfig.affiliateId),callback:function ($$v) {_vm.$set(_vm.permitConfig, "affiliateId", $$v)},expression:"permitConfig.affiliateId"}})],1)],1),_c('el-col',{attrs:{"lg":8,"md":20}},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addPermit}},[_vm._v("Add")])],1)],1)],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.permitList,"highlight-current-row":"","border":"","stripe":"","height":"80vmin"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":"AffiliateId","prop":"affiliateId"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"cor337ab7",on:{"click":function($event){return _vm.toAffiliateDetailClick(scope.row)}}},[_vm._v(_vm._s(scope.row.affiliateId))])]}}])}),_c('el-table-column',{attrs:{"label":"Creator","prop":"createBy"}}),_c('el-table-column',{attrs:{"label":"CreateTime","prop":"createTime"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }