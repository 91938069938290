<template>
  <div>
    <el-card class="offerInfo">
      <div slot="header" class="clearfix">
        <span class="detail_title">Offer Info</span>

        <el-button type="primary" class="detail_button" @click="updateAsyncModalClick"
          >Update
        </el-button>
        <el-button class="detail_button" @click="updateTimedTaskClick" style="margin-right:10px;"
          >TimedTask
        </el-button>
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="3" :md="20">
            <p class="detail_item">
              offer id :
              <span>{{ offer.offerId }}</span>
            </p>
          </el-col>
          <el-col :lg="7" :md="20" :offset="1">
            <p class="detail_item">
              offer name :
              <span>{{ offer.offerName }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              status :
              <span>{{ offer.status }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              Control Type :
              <span>{{ offer.controlType }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              prod name :
              <span>{{ offer.prod }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              Async Click:
              <span>{{ offer.async }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              Timed Task Status:
              <span>{{ offer.openStatus }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              Open Time :
              <span>{{ offer.openTime }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              Close Time :
              <span>{{ offer.closeTime }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="sourceInfo">
      <div slot="header" class="clearfix">
        <span class="detail_title">Source Info</span>
        <el-button type="primary" class="detail_button" @click="updateSourceModalClick"
          >Update
        </el-button>
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              source id :
              <span>{{ offer.sourceId }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              source offer id :
              <span>{{ offer.sourceOfferId }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              source tracking link :
              <span>{{ offer.sourceTrackingLink }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              tracking link :
              <span>{{ offer.trackingLink }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              settle event :
              <span>{{ offer.settleEvent }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              campaign name :
              <span>{{ offer.campaignName }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              campaign cap :
              <span>{{ offer.campaignCap }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              campaign status :
              <span>{{ offer.campaignStatus }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px;">
          <el-col :lg="15" :md="20">
            <p class="detail_item">
              modify the source tracking link
              <el-input
                v-model="sourceTrackinglink"
                :disabled="offer.controlType === 'api'"
                placeholder="source tracking link"
                style="width:320px;"
              ></el-input>
              <el-button type="primary" @click="updateTrackinglinkClick" style="margin-left:10px;"
                >Update
              </el-button>
            </p>
          </el-col>
          <el-col :lg="8" :md="20" :offset="1">
            <p class="detail_item">
              Is Smart Tracking:
              <el-switch
                v-model="smartStatus"
                :active-value="1"
                :inactive-value="0"
                @change="changeSmartStatus"
                :disabled="smartStatus === 2"
              />
            </p>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px;">
          <el-col :lg="18" :md="20">
            <p class="detail_item">
              modify the settle event
              <el-input
                v-model="settleEventValue"
                placeholder="settle Event here"
                style="width:320px;"
              ></el-input>
              <el-button type="primary" @click="updateSettleEventClick" style="margin-left:10px;"
                >Update
              </el-button>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="payoutInfo">
      <div slot="header" class="clearfix">
        <span class="detail_title">Payout Info</span>
        <el-button type="primary" class="detail_button" @click="updatePayoutModalClick"
          >Update
        </el-button>
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              currency :
              <span>{{ offer.currency }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              payout :
              <span>{{ offer.payout }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              revenue :
              <span>{{ offer.revenue }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              payout type :
              <span>{{ offer.payoutType }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              cap daily :
              <span>{{ offer.capDaily }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              daily revenue :
              <span>{{ offer.dailyRevenue }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              click cap daily :
              <span>{{ offer.clickCapDaily }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              allow deduct :
              <span v-if="offer.allowDeduct == 1">Avaliable</span>
              <span v-else>Disabling</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              manual cap daily :
              <span>{{ offer.manualCapDaily }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              Click Cap Incr Fix :
              <span>{{ offer.clickCapIncrFix }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="settingInfo">
      <div slot="header" class="clearfix">
        <span class="detail_title">Setting Info</span>
        <el-button type="primary" class="detail_button" @click="updateSettingModalClick"
          >Update
        </el-button>
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              platforms :
              <span>{{ offer.platforms }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              countries :
              <el-tooltip>
                <div slot="content" style="max-width:500px">{{ offer.countries }}</div>
                <span
                  class="line-overflow"
                  style="cursor: pointer;"
                  v-clipboard:copy="offer.countries"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onCopyError"
                  >{{ offer.countries }}</span
                >
              </el-tooltip>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              regions :
              <span>{{ offer.regions }}</span>
            </p>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <p class="detail_item">
              cities :
              <span>{{ offer.citys }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              regionsBlock :
              <span>{{ offer.regionsBlock }}</span>
            </p>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <p class="detail_item">
              citysBlock :
              <span>{{ offer.citysBlock }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              download type :
              <span>{{ offer.downloadType }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              gid idfa needs :
              <span>{{ offer.gidIdfaNeeds }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              offer whitelist limited :
              <span>{{ offer.whitelistLimited }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              carriers :
              <span>{{ offer.carriers }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              reject open :
              <span>{{ offer.rejectOpen }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              reject rate :
              <span>{{ offer.rejectRate }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              Traffic Tag :
              <span>{{ offer.trafficTag }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              ClickLimited Number :
              <span>{{ offer.crBlackClicks }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              Countries Block :
              <span>{{ offer.countriesBlock }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              direct :
              <span>{{ offer.direct }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              Af Prt :
              <span>{{ offer.afPrt }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="Description">
      <div slot="header" class="clearfix">
        <span class="detail_title">Description Info</span>
        <el-button
          type="primary"
          class="detail_button"
          :disabled="offer.controlType === 'api'"
          @click="updatedescModalClick"
          >Update
        </el-button>
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              preview link :
              <span>{{ offer.previewLink }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              offer category :
              <span>{{ offer.offerCategory }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              description:
              <span>{{ offer.description }}</span>
            </p>
          </el-col>

          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              short description :
              <span>{{ offer.shortDescription }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              traffic allowed :
              <span>{{ offer.trafficAllowed }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              traffic forbidden :
              <span>{{ offer.trafficForbidden }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              conversion flow:
              <span>{{ offer.conversionFlow }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              incentive :
              <span>{{ offer.incentive }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              osMinVersion :
              <span>{{ offer.osMinVersion }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              classifyType :
              <span>{{ offer.classifyType }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!-- 定时任务 模块框 -->
    <el-dialog
      :visible.sync="timedTaskVisible"
      title="update Time Task"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
    >
      <el-form :model="timedTask" label-position="right">
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Status" label-width="100px">
              <el-switch
                v-model="timedTask.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Open Time" label-width="100px">
              <el-time-select
                placeholder="Open Time"
                v-model="timedTask.openTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:01',
                  end: '23:59',
                }"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Close Time" label-width="100px">
              <el-time-select
                placeholder="Close Time"
                v-model="timedTask.closeTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:01',
                  end: '23:59',
                }"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <p :style="{ fontSize: '12px', color: 'red', lineHeight: '14px', marginTop: '10px' }"
            >The difference between the opening and closing time must be greater than 15 minutes</p
          >
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="timedTaskVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTimedTask">确 定</el-button>
      </span>
    </el-dialog>
    <!-- payout 模块框 -->
    <el-dialog
      :visible.sync="payoutVisible"
      title="update Payout Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="payoutInfo" label-position="left">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Revenue" label-width="100px">
              <el-input
                :disabled="offer.controlType === 'api'"
                v-model="payoutInfo.revenue"
                placeholder="please add revenue..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Payout" label-width="100px">
              <el-input v-model="payoutInfo.payout" placeholder="please add payout..."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Cap Daily" label-width="100px">
              <el-input
                :disabled="offer.controlType === 'api'"
                v-model="payoutInfo.capDaily"
                placeholder="please add cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Manual Cap Daily" label-width="120px">
              <el-input
                v-model="payoutInfo.manualCapDaily"
                placeholder="please add manual cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Daily Revenue" label-width="100px">
              <el-input
                v-model="payoutInfo.dailyRevenue"
                placeholder="please add daily revenue..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Click Cap Daily" label-width="120px">
              <el-input
                v-model="payoutInfo.clickCapDaily"
                placeholder="please add manual click cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Allow Deduct" label-width="100px">
              <el-select v-model="payoutInfo.allowDeduct">
                <el-option
                  v-for="item in deductOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Click Cap Incr Fix" label-width="120px">
              <el-input v-model="payoutInfo.clickCapIncrFix"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payoutVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePayoutClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- description模块框 -->
    <el-dialog
      :visible.sync="descriptionVisible"
      title="Update Description Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="descriptionInfo" label-position="left">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="ClassifyType" label-width="100px">
              <el-select v-model="descriptionInfo.classifyType">
                <el-option
                  v-for="item in classifyTypeOption"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Description" label-width="100px">
              <el-input
                v-model="descriptionInfo.description"
                placeholder="please add description..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Short Description" label-width="130px">
              <el-input
                v-model="descriptionInfo.shortDescription"
                placeholder="please add short description..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Preview link" label-width="100px">
              <el-input
                v-model="descriptionInfo.previewLink"
                placeholder="please add previewLink..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Category" label-width="100px">
              <el-input
                v-model="descriptionInfo.category"
                placeholder="please add category..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Traffic Allowed" label-width="120px">
              <el-input
                v-model="descriptionInfo.trafficAllowed"
                placeholder="please add traffic allowed..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <el-col :lg="11" :md="20">
              <el-form-item label="Traffic Forbidden" label-width="130px">
                <el-input
                  v-model="descriptionInfo.trafficForbidden"
                  placeholder="please add trafficForbidden..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Concersion Flow" label-width="130px">
                <el-input
                  v-model="descriptionInfo.conversionFlow"
                  placeholder="please add conversionFlow..."
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="descriptionVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDescClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- async模块框 -->
    <el-dialog
      :visible.sync="settingasyncVisible"
      title="update Async Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="asyncSetting" label-position="left">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="offerName" label-width="100px">
              <el-input v-model="asyncSetting.offerName" :disabled="offer.controlType === 'api'" />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="controlType" label-width="100px">
              <el-select v-model="asyncSetting.controlType" :disabled="offer.controlType === 'api'">
                <el-option
                  v-for="item in controlTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Prod Name" label-width="100px">
              <el-input v-model="asyncSetting.prod" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Async Click" label-width="100px">
              <el-switch
                v-model="asyncSetting.async"
                active-text="Yes"
                inactive-text="No"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="TRUE"
                inactive-value="FALSE"
                @change="asyncChangeHandle"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingasyncVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAsyncInfoClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- setting模块框 -->
    <el-dialog
      :visible.sync="settingVisible"
      title="update Setting Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="settingInfo" label-position="left" label-width="100px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Platform">
              <el-select v-model="settingInfo.platforms">
                <el-option
                  v-for="item in platformOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Download Type" label-width="120px">
              <el-select v-model="settingInfo.downloadType">
                <el-option
                  v-for="item in downloadTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Countries">
              <el-input
                v-model="settingInfo.countries"
                placeholder="please add countries..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Citys">
              <el-input v-model="settingInfo.citys" placeholder="please add citys..."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Carriers">
              <el-input
                v-model="settingInfo.carriers"
                placeholder="please add carriers..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Regions">
              <el-input
                v-model="settingInfo.regions"
                placeholder="please add regions..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Traffic Type">
              <el-select v-model="settingInfo.trafficTag" multiple filterable clearable>
                <el-option
                  v-for="item in trafficTagList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="ClickLimited Number" label-width="140px">
              <el-input
                v-model="settingInfo.crBlackClicks"
                placeholder="please add clickLimited number..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="CountriesBlock" label-width="140px">
              <el-input
                v-model="settingInfo.countriesBlock"
                placeholder="please add countriesBlock number..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="RegionsBlock" label-width="140px">
              <el-input
                v-model="settingInfo.regionsBlock"
                placeholder="please add regionsBlock number..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="CitysBlock" label-width="140px">
              <el-input
                v-model="settingInfo.citysBlock"
                placeholder="please add citysBlock number..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Direct" label-width="140px">
              <el-select
                v-model="settingInfo.direct"
                clearable
                placeholder="please add direct number..."
              >
                <el-option label="direct" value="direct"></el-option>
                <el-option label="on_direct" value="non_direct"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="AfPrt" label-width="140px">
              <el-input v-model="settingInfo.afPrt" placeholder="please add af prt..." />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSettingClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- source Info模态框 -->
    <el-dialog
      :visible.sync="sourceInfoVisible"
      title="update Source Info"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
      @close="sourceModalCancel"
    >
      <el-form
        ref="sourceInfoForm"
        :model="sourceInfo"
        label-position="right"
        :rules="sourceRules"
        label-width="129px"
      >
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Campaign Name" prop="campaignName">
              <el-input v-model="sourceInfo.campaignName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Cap" prop="cap">
              <el-input v-model.number="sourceInfo.cap"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Status">
              <el-switch
                v-model="sourceInfo.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sourceInfoVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSourceInfoClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    changeOfferCampaignName,
    getOfferDetail,
    updateOfferAsyncInfo,
    updateOfferDescription,
    updateOfferPayout,
    updateOfferSetting,
    updateOfferSmartStatusApi,
    updateOfferSource,
    updateOfferTimedTask,
  } from 'api/offer/detail';
  import { getAllTags } from 'api/affiliate/tag';
  import { filterObject } from '@/utils/utils';
  import { cloneDeep } from 'lodash-es';
  import message from 'element-ui/packages/message';

  export default {
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        offer: {},
        descriptionVisible: false,
        descriptionInfo: {
          offerId: this.offerId,
          description: '',
          shortDescription: '',
          previewLink: '',
          category: '',
          trafficAllowed: '',
          trafficForbidden: '',
          conversionFlow: '',
          classifyType: '',
        },
        loading: false,
        classifyTypeOption: [
          {
            label: '',
            value: '',
          },
          {
            label: 'adult',
            value: 'adult',
          },
          {
            label: 'mainstream',
            value: 'mainstream',
          },
        ],
        carriersGroup: [],
        payoutVisible: false,
        payoutInfo: {
          offerId: this.$route.query.offerId,
          revenue: '',
          capDaily: '',
          payout: '',
          manualCapDaily: '',
          dailyRevenue: '',
          clickCapDaily: '',
          allowDeduct: '',
          clickCapIncrFix: '',
        },
        controlTypeOption: [
          {
            label: 'private',
            value: 'private',
          },
          {
            label: 'public',
            value: 'public',
          },
        ],
        deductOption: [
          {
            label: 'Disabling',
            value: 0,
          },
          {
            label: 'Available',
            value: 1,
          },
        ],
        settingInfo: {
          offerId: this.$route.query.offerId,
          platforms: null,
          countries: null,
          citys: null,
          carriers: null,
          regions: null,
          downloadType: null,
          trafficTag: null,
          crBlackClicks: null,
          countriesBlock: null,
          direct: '',
          regionsBlock: '',
          citysBlock: '',
          afPrt: '',
        },
        settingasyncVisible: false,
        asyncSetting: {
          offerName: '',
          controlType: '',
          async: null,
          prod: '',
        },
        sourceTrackinglink: '',
        settleEventValue: '',
        capSetting: {},
        clickDailyCap: null,
        mixClickOption: [
          {
            label: 'CLOSE',
            value: '0',
          },
          {
            label: 'OPEN',
            value: '1',
          },
        ],
        downloadTypeOption: [
          {
            label: 'GP',
            value: 'GP',
          },
          {
            label: 'ITUNES',
            value: 'ITUNES',
          },
          {
            label: 'OTHERS',
            value: 'OTHERS',
          },
        ],
        platformOption: [
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
          {
            label: 'PC',
            value: 'PC',
          },
          {
            label: 'ALL',
            value: 'ALL',
          },
        ],
        settingVisible: false,
        timedTask: {
          offerId: '',
          status: false,
          openTime: '',
          closeTime: '',
        },
        timedTaskVisible: false,
        trafficTagList: [],
        sourceInfoVisible: false,
        sourceInfo: {},
        sourceRules: {
          campaignName: [
            {
              required: true,
              trigger: ['blur', 'change'],
              message: 'Campaign Name不能为空',
            },
          ],
          cap: [
            {
              required: true,
              trigger: ['blur', 'change'],
              message: 'Cap不能为空',
            },
            { type: 'number', message: 'Cap必须为数值' },
          ],
        },
        smartStatus: 2,
      };
    },
    mounted() {
      this.getOfferDetail();
    },
    methods: {
      getOfferDetail() {
        getOfferDetail(this.offerId).then((response) => {
          if (response.code === 200) {
            this.offer = response.result;
            this.smartStatus = response.result.smartStatus ?? 0;
          }
        });
      },
      updatePayoutModalClick() {
        this.payoutVisible = true;
        this.payoutInfo.revenue = this.offer.revenue || '';
        this.payoutInfo.capDaily = this.offer.capDaily || '';
        this.payoutInfo.payout = this.offer.payout || '';
        this.payoutInfo.manualCapDaily = this.offer.manualCapDaily || '';
        this.payoutInfo.dailyRevenue = this.offer.dailyRevenue || '';
        this.payoutInfo.clickCapDaily = this.offer.clickCapDaily || '';
        this.payoutInfo.allowDeduct = this.offer.allowDeduct || '';
        this.payoutInfo.clickCapIncrFix = this.offer.clickCapIncrFix || '';
      },
      updateTrackinglinkClick() {
        const param = {
          offerId: this.offerId,
          sourceTrackingLink: this.sourceTrackinglink,
        };
        updateOfferSource(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.sourceTrackinglink = '';
            this.getOfferDetail();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateSettleEventClick() {
        let param = {
          offerId: this.offerId,
          settleEvent: this.settleEventValue,
        };
        updateOfferSource(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.settleEventValue = '';

            this.getOfferDetail();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updatedescModalClick() {
        this.descriptionVisible = true;
        this.descriptionInfo.description = this.offer.description;
        this.descriptionInfo.shortDescription = this.offer.shortDescription;
        this.descriptionInfo.previewLink = this.offer.previewLink;
        this.descriptionInfo.category = this.offer.offerCategory;
        this.descriptionInfo.trafficAllowed = this.offer.trafficAllowed;
        this.descriptionInfo.trafficForbidden = this.offer.trafficForbidden;
        this.descriptionInfo.conversionFlow = this.offer.conversionFlow;
        this.descriptionInfo.classifyType = this.offer.classifyType;
      },
      updateDescClick() {
        let param = {
          ...cloneDeep(this.descriptionInfo),
        };
        updateOfferDescription(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.offer.description = this.descriptionInfo.description;
            this.offer.shortDescription = this.descriptionInfo.shortDescription;
            this.offer.previewLink = this.descriptionInfo.previewLink;
            this.offer.offerCategory = this.descriptionInfo.category;
            this.offer.trafficAllowed = this.descriptionInfo.trafficAllowed;
            this.offer.trafficForbidden = this.descriptionInfo.trafficForbidden;
            this.offer.conversionFlow = this.descriptionInfo.conversionFlow;
            this.offer.classifyType = this.descriptionInfo.classifyType;
            this.descriptionVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      updatePayoutClick() {
        let param = {
          ...this.payoutInfo,
        };
        updateOfferPayout(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
            this.payoutVisible = false;
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateAsyncInfoClick() {
        const param = {
          offerId: this.offerId,
          ...filterObject(this.asyncSetting),
        };
        updateOfferAsyncInfo(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
            this.settingasyncVisible = false;
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateAsyncModalClick() {
        this.settingasyncVisible = true;
        this.asyncSetting.controlType = this.offer.controlType;
        this.asyncSetting.offerName = this.offer.offerName;
        this.asyncSetting.prod = this.offer.prod;
        this.asyncSetting.async = this.offer.async;
      },
      updateSettingClick() {
        const param = {
          ...cloneDeep(this.settingInfo),
        };
        if (Array.isArray(this.settingInfo.trafficTag)) {
          param.trafficTag = this.settingInfo.trafficTag?.join(',');
        }
        updateOfferSetting(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
            this.settingVisible = false;
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateSettingModalClick() {
        // 获取trafficTag 列表
        this.getTrafficTypeList();
        this.settingVisible = true;

        this.settingInfo.platforms = this.offer.platforms || '';
        this.settingInfo.affSubLevel = this.offer.affSubLevel || '';
        this.settingInfo.countries = this.offer.countries || '';
        this.settingInfo.citys = this.offer.citys || '';
        this.settingInfo.carriers = this.offer.carriers || '';
        this.settingInfo.regions = this.offer.regions || '';
        this.settingInfo.downloadType = this.offer.downloadType || '';
        this.settingInfo.trafficTag = this.offer.trafficTag?.split(',') || [];
        this.settingInfo.crBlackClicks = this.offer.crBlackClicks || '';
        this.settingInfo.countriesBlock = this.offer.countriesBlock || '';
        this.settingInfo.direct = this.offer.direct || '';
        this.settingInfo.afPrt = this.offer.afPrt || '';
      },
      onCopy(e) {
        if (e.text) {
          this.$message.success('Copy success');
        }
      },
      onCopyError() {
        this.$message.error('Copy Error');
      },
      updateTimedTaskClick() {
        this.timedTaskVisible = true;
        this.timedTask = {
          offerId: this.offer.offerId,
          status: this.offer.openStatus,
          openTime: this.offer.openTime?.substring(0, 5),
          closeTime: this.offer.closeTime?.substring(0, 5),
        };
      },
      updateTimedTask() {
        const param = {
          ...this.timedTask,
        };
        updateOfferTimedTask(param).then((resp) => {
          if (resp.code === 200) {
            this.$message.success('Update Success');
            this.getOfferDetail();
            this.timedTaskVisible = false;
          } else {
            this.$message.error(resp.message);
          }
        });
      },
      asyncChangeHandle(val) {
        const index = ['app.appsflyer.com', 'app.adjust.com'].findIndex((item) =>
          this.offer.sourceTrackingLink?.includes(item)
        );
        if (val === 'TRUE' && index === -1) {
          this.$message.warning('Async click only available for appsflyer.');
          this.asyncSetting.async = 'FALSE';
          return;
        }
      },
      getTrafficTypeList() {
        getAllTags('offer').then((resp) => {
          if (resp.code === 200) {
            this.trafficTagList = resp.result?.map((item) => ({
              label: item.trafficTag,
              value: item.trafficTag,
              key: item.id,
            }));
          } else {
            this.trafficTagList = [];
          }
        });
      },
      updateSourceModalClick() {
        this.sourceInfoVisible = true;
        this.sourceInfo = {
          offerId: this.offer.offerId,
          campaignName: this.offer.campaignName,
          cap: this.offer.campaignCap,
          status: this.offer.campaignStatus,
        };
      },
      sourceModalCancel() {
        this.$refs.sourceInfoForm.clearValidate();
      },
      updateSourceInfoClick() {
        this.$refs.sourceInfoForm.validate((valid) => {
          if (!valid) return;
          const params = { ...this.sourceInfo };
          changeOfferCampaignName(params)
            .then((resp) => {
              if (resp.code === 200) {
                this.$message.success('Change Success');
                this.getOfferDetail();
                this.sourceInfoVisible = false;
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        });
      },
      async changeSmartStatus(status) {
        try {
          await updateOfferSmartStatusApi({
            smartStatus: status,
            offerId: this.offer.offerId,
            sourceId: this.offer.sourceId,
            sourceOfferId: this.offer.sourceOfferId,
          });
          message.success('change smart status success');
        } catch (error) {
          console.error(error);
          this.smartStatus = status ^ 1;
          message.error('change smart status error');
        }
        console.log(status, 'status');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .detail_title {
    font-size: 22px;
  }

  .detail_row {
    margin-bottom: 10px;
  }

  .detail_button {
    float: right;
  }

  .detail_item {
    margin-left: 20px;
    color: #337ab7;
    font-size: 16px;

    span {
      color: #303133;
      font-size: 14px;
      vertical-align: middle;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }

    .line-overflow {
      display: inline-block;
      max-width: 350px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
</style>
