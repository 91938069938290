<template>
  <div>
    <div class="button_group">
      <el-form :inline="true" :model="filter">
        <el-form-item label="SourceId(s):" label-width="80px">
          <group-select
            v-model="filter.sourceId"
            :data-source="sourcesList"
            :loading="sourceLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="SubOfferId">
          <el-input v-model="filter.subOfferId" placeholder="source offer id here"></el-input>
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="filter.status" placeholder="source offer id here" clearable>
            <el-option
              v-for="option in statusOptions"
              :key="option.value"
              :value="option.value.toString()"
              :label="option.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="EventName">
          <el-input v-model="filter.eventName" placeholder="event name here" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getOfferSourceTrackingList(1)">Search</el-button>
          <el-button type="primary" @click="openDialog('add')">Add</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="dataList" border style="width:100%;" height="80vmin" highlight-current-row>
      <el-table-column
        label="SourceId"
        prop="sourceId"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="SubOfferId"
        prop="subOfferId"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column label="Status" prop="status" align="center"></el-table-column> -->
      <el-table-column
        label="SourceTracking"
        prop="sourceTracking"
        width="130"
        align="center"
      ></el-table-column>
      <el-table-column label="Revenue" prop="revenue" width="120" align="center"></el-table-column>
      <el-table-column
        label="Daily Cap"
        prop="dailyCap"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Daily Click Cap"
        width="130"
        prop="dailyClickCap"
        align="center"
      ></el-table-column>
      <el-table-column
        label="EventName"
        width="130"
        prop="eventName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Status"
        min-width="80"
        prop="status"
        align="center"
        show-overflow-tooltip
      >
        <template #default="{row}">
          <span v-if="row.status === 0">关闭</span>
          <span v-else-if="row.status === 1">开启</span>
          <span v-else-if="row.status === 2">cap跑满</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Action"
        prop="action"
        width="100"
        align="center"
        show-overflow-tooltip
      >
        <template #default="{row}">
          <el-button type="primary" @click="openDialog('update', row)">update</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="tableDataTotal"
      @handleSizeChange="handleTableSizeChange"
      @handleCurrentChange="handleTableCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <el-dialog
      :title="dialogConfig.type === 'add' ? 'Add' : 'Update'"
      :visible.sync="dialogConfig.visible"
      width="800px"
    >
      <el-form
        ref="dialogForm"
        :model="offerSourceTrackingesForm"
        label-position="left"
        label-width="120px"
        :rules="dialogFormRule"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="SourceId" prop="sourceId">
              <group-select
                v-model="offerSourceTrackingesForm.sourceId"
                :data-source="sourcesList"
                :loading="sourceLoading"
                clearable
                reserve-keyword
                filterable
                placeholder="请选择"
                style="width:100%"
                :disabled="dialogConfig.type !== 'add'"
              />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="SubOfferId" prop="subOfferId">
              <el-input
                v-model="offerSourceTrackingesForm.subOfferId"
                :disabled="dialogConfig.type !== 'add'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="SourceTracking" prop="sourceTracking">
              <el-input v-model="offerSourceTrackingesForm.sourceTracking"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Revenue">
              <el-input v-model="offerSourceTrackingesForm.revenue"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="DailyCap" prop="dailyCap">
              <el-input v-model="offerSourceTrackingesForm.dailyCap"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="DailyClickCap" prop="dailyClickCap">
              <el-input v-model="offerSourceTrackingesForm.dailyClickCap"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Status" prop="status">
              <el-select v-model="offerSourceTrackingesForm.status" style="width:100%">
                <el-option
                  v-for="option in statusOptions.filter((item) => item.value !== 2)"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Weight" prop="weight">
              <el-input v-model="offerSourceTrackingesForm.weight"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="EventName" prop="eventName">
              <el-input v-model="offerSourceTrackingesForm.eventName" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfig.visible = false">取 消</el-button>
        <el-button type="primary" @click="confirmForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { changeOfferSouceTrackingesApi, getOfferSourceTrackingesListApi } from 'api/offer/detail';

  import Pagination from '@/components/pagination';
  import { filterObject } from 'utils/utils';
  import { myMixin } from '@/mixins/mixins.js';
  import GroupSelect from '@/components/GroupSelect/index.vue';
  import { getAllSourceList } from 'api/source';

  const defaultForm = {
    sourceId: '',
    subOfferId: '',
    sourceTracking: '',
    revenue: '',
    dailyCap: '',
    dailyClickCap: '',
    status: 1,
    weight: '',
    eventName: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      GroupSelect,
      Pagination,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      const validateDailyClickCapAndDailyCap = (rule, value, callback) => {
        const { dailyCap, dailyClickCap } = this.offerSourceTrackingesForm ?? {};
        if (!dailyCap && !dailyClickCap) {
          callback(new Error('Fill in at least one'));
          return;
        }
        callback();
      };

      return {
        filter: {},
        offerSourceTrackingesForm: {},
        statusOptions: [
          {
            label: '关闭',
            value: 0,
          },
          {
            label: '开启',
            value: 1,
          },
          {
            label: 'cap跑满',
            value: 2,
          },
        ],
        sourceLoading: false,
        sourcesList: [],
        dataList: [],
        tableDataTotal: 0,
        currentPage: 1,
        pageSize: 20,
        dialogConfig: {
          visible: false,
          type: 'add',
        },
        dialogFormRule: {
          sourceId: [
            {
              required: true,
              message: 'please select sourceId',
            },
          ],
          subOfferId: [
            {
              required: true,
              message: 'please input subOfferId',
            },
          ],
          sourceTracking: [
            {
              required: true,
              message: 'please select sourceTracking',
            },
          ],
          status: [
            {
              required: true,
              message: 'please select status',
            },
          ],
          dailyCap: [
            {
              validator: validateDailyClickCapAndDailyCap,
            },
          ],
          dailyClickCap: [
            {
              validator: validateDailyClickCapAndDailyCap,
            },
          ],
        },
      };
    },
    mounted() {
      this.getSourceList();
      this.getOfferSourceTrackingList();
    },
    methods: {
      getOfferSourceTrackingList(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = filterObject({
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        });

        getOfferSourceTrackingesListApi(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.dataList = response.result;
            this.tableDataTotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      async confirmForm() {
        try {
          await this.$refs.dialogForm?.validate();
          const { code, message } = await changeOfferSouceTrackingesApi(
            this.offerId,
            this.offerSourceTrackingesForm
          );
          if (code === 200) {
            this.$message.success(`${this.dialogConfig.type === 'add' ? 'add' : 'update'} success`);
            this.getOfferSourceTrackingList();
            this.dialogConfig.visible = false;
          } else {
            this.$message.error(message);
          }
        } catch (e) {
          console.error(e);
        }
      },
      openDialog(type = 'add', formInfo) {
        this.offerSourceTrackingesForm = Object.assign({}, defaultForm, formInfo);
        this.dialogConfig.type = type;
        this.dialogConfig.visible = true;
        this.$refs.dialogForm?.clearValidate();
      },
      async getSourceList() {
        this.sourceLoading = true;
        const response = await getAllSourceList();

        let sourcesList = [];
        if (response.code == 200) {
          let sourceIds = response.result;
          const CPIGorups = {
            id: 1,
            checked: false,
            label: 'CPI',
            children: [],
          };

          const CPAGorups = {
            id: 2,
            checked: false,
            label: 'CPA',
            children: [],
          };
          const SmartLinkGroups = {
            id: 3,
            checked: false,
            label: 'SmartLink',
            children: [],
          };

          const CPLGroups = {
            id: 4,
            checked: false,
            label: 'CPL',
            children: [],
          };

          const PausedPending = {
            id: 5,
            checked: false,
            label: 'Paused&Pending',
            children: [],
          };
          sourceIds.forEach((item) => {
            if (item.enable !== 'ENABLE') {
              PausedPending.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            } else if (item.payoutType == 'CPI') {
              CPIGorups.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            } else if (item.payoutType == 'CPA') {
              CPAGorups.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            } else if (item.payoutType == 'CPL') {
              CPLGroups.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            }
          });
          sourcesList.push(CPIGorups, CPAGorups, SmartLinkGroups, CPLGroups, PausedPending);
          this.sourcesList = sourcesList;
        }
        this.sourceLoading = false;
      },
      handleTableSizeChange(val) {
        this.pageSize = val;
        this.handleTableCurrentChange(1);
      },
      handleTableCurrentChange(val) {
        this.currentPage = val;
        this.getOfferSourceTrackingList();
      },
    },
  };
</script>

<style lang="scss">
  .el-table .gray-row {
    background-color: gray !important;
  }

  .err-tip .el-message-box__message {
    color: red;
    font-weight: bolder;
  }
</style>
