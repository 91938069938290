<template>
  <div>
    <div class="button_group">
      <el-form :inline="true" :model="filter">
        <el-form-item label="AffilaiteId(s):" label-width="80px">
          <el-input v-model="filter.affiliateId" placeholder="AffilaiteId(s) here"></el-input>
        </el-form-item>
        <el-form-item label="CreateTime">
          <el-date-picker
            v-model="startToEndDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            style="width:260px;"
            align="center"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAffWhitelist(1)">Search</el-button>
          <el-button type="primary" @click="delAffClick">Delete</el-button>
          <!-- addVisible= true -->
          <el-button type="primary" @click="addAffWhiteClick">Add</el-button>
          <el-button type="primary" @click="updateAffWhiteListClick">Batch Update</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="whiteList"
      border
      style="width:100%;"
      height="80vmin"
      @selection-change="handleAffWhiteSelectionChange"
      @select-all="handleAffWhiteSelectionChange"
      :row-class-name="tableRowClassName"
      highlight-current-row
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        label="AffiliateId"
        prop="affiliateId"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="AffiliateName"
        prop="affiliateName"
        min-width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column label="Status" prop="status" align="center"></el-table-column> -->
      <el-table-column
        label="Daily Cap"
        prop="dailyCap"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="OriginPayout"
        prop="originPayout"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Payout Value"
        prop="payoutValue"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Payout Percent"
        width="130"
        prop="payoutPercent"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Description"
        min-width="230"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operator"
        prop="operator"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="OperateTime"
        prop="operateTime"
        width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="affWhiteTotal"
      @handleSizeChange="handleofferWhiteSizeChange"
      @handleCurrentChange="handleofferWhiteCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delOfferVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delOfferVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAff">confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Add Affilaite" :visible.sync="addVisible">
      <el-form :model="affilaite" label-position="left" label-width="110px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="AffiliateId">
              <el-input v-model="affilaite.affiliateId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="DailyCap">
              <el-input v-model="affilaite.dailyCap"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Payout Value">
              <el-input v-model="affilaite.payoutValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Payout Percent">
              <el-input v-model="affilaite.payoutPercent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Description">
              <el-input v-model="affilaite.description"></el-input>
            </el-form-item> </el-col
        ></el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Update" :visible.sync="updateVisible">
      <el-form :model="updateAff" label-position="left" label-width="110px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="DailyCap">
              <el-input v-model="updateAff.dailyCap"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Payout Value">
              <el-input v-model="updateAff.payoutValue"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Payout Percent">
              <el-input v-model="updateAff.payoutPercent"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Description">
              <el-input v-model="updateAff.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAffWhiteList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getAffWhitelist,
    addAffWhitelist,
    deleteAffWhitelist,
    batchUpdateAffWhite,
    // deleteAllOfferWhiteList,
  } from 'api/offer/detail';
  import { confirmTraffic } from '@/api/affiliate/detail';

  import Pagination from '@/components/pagination';
  import { filterObject } from 'utils/utils';
  import { myMixin } from '@/mixins/mixins.js';
  const defaultAffilaite = {
    affiliateId: '',
    dailyCap: '',
    payoutValue: '',
    payoutPercent: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        statusOptions: [
          {
            label: 'active',
            value: 'active',
          },
          {
            label: 'paused',
            value: 'paused',
          },
        ],
        affWhiteSelectionList: [],
        whiteList: [],
        affWhiteTotal: null,
        currentPage: 1,
        pageSize: 20,
        delOfferVisible: false,
        addVisible: false,
        updateVisible: false,
        affilaite: {
          affiliateId: null,
          dailyCap: null,
        },
        startToEndDate: [],
        updateAff: {},
      };
    },
    mounted() {
      this.getAffWhitelist();
    },
    methods: {
      getAffWhitelist(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = filterObject({
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        });
        if (this.startToEndDate?.length === 2) {
          [param.fromDate, param.toDate] = this.startToEndDate;
        }
        getAffWhitelist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.whiteList = response.result;
            this.affWhiteTotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      // deleteAll() {
      //   this.$confirm('确定要删除清空Offer White List吗？', 'Tip', {
      //     confirmButtonText: 'Confirm',
      //     cancelButtonText: 'Cancel',
      //   })
      //     .then(() => {
      //       deleteAllOfferWhiteList(this.offerId).then((response) => {
      //         if (response.code === 200) {
      //           this.$message.success('Offer White List已清空');
      //           this.getAffWhitelist();
      //         }
      //       });
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: 'info',
      //         message: '已取消删除',
      //       });
      //     });
      // },
      addAffWhiteClick() {
        this.affilaite = Object.assign({}, defaultAffilaite);
        this.addVisible = true;
      },
      updateAffWhiteListClick() {
        if (this.affWhiteSelectionList.length === 0) {
          this.$message.warning('你还没有选择要修改的数据');
          return;
        }
        this.updateVisible = true;
      },
      updateAffWhiteList() {
        this.$confirm('确定要批量修改数据吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const ids = this.whiteList
              .filter((item) => this.affWhiteSelectionList.includes(item.affiliateId))
              .map((item) => item.id);
            batchUpdateAffWhite({
              ids,
              ...this.updateAff,
            }).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.updateVisible = false;
                this.getAffWhitelist();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消修改',
            });
          });
      },
      handleAffWhiteSelectionChange(val) {
        this.affWhiteSelectionList = val.map((item) => item.affiliateId);
      },
      tableRowClassName(row) {
        if (row.row.offerStatus == 'hold') {
          return 'gray-row';
        } else {
          return '';
        }
      },
      delAffClick() {
        if (this.affWhiteSelectionList.length === 0) {
          this.$message.warning('你还没有输入offerIds~');
          return;
        }
        this.delOfferVisible = true;
      },
      delAff() {
        const affiliateIds = this.affWhiteSelectionList.join(',');
        deleteAffWhitelist(this.offerId, affiliateIds).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.filter.offerId = '';
            this.getAffWhitelist();
            this.delOfferVisible = false;
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      checkConfirm() {
        const offerIds = this.offerId.split(',');
        confirmTraffic(offerIds).then((resp) => {
          if (resp.code === 200) {
            if (resp.result && resp.result.tips) {
              this.$confirm(resp.result.tips, 'Tip', {
                customClass: 'err-tip',
              }).then(() => {
                this.addClick();
              });
              return;
            } else {
              this.addClick();
            }
          }
        });
      },
      addClick() {
        const param = {
          ...this.affilaite,
        };
        addAffWhitelist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add success',
              type: 'success',
            });
            this.getAffWhitelist();
            this.addVisible = false;
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      handleofferWhiteSizeChange(val) {
        this.pageSize = val;
        this.handleofferWhiteCurrentChange(1);
      },
      handleofferWhiteCurrentChange(val) {
        this.currentPage = val;
        this.getAffWhitelist();
      },
    },
  };
</script>

<style lang="scss">
  .el-table .gray-row {
    background-color: gray !important;
  }
  .err-tip .el-message-box__message {
    color: red;
    font-weight: bolder;
  }
</style>
