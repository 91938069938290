<template>
  <div class="offer_detail">
    <div class="title">
      <h2>Detail</h2>
    </div>
    <div>
      <el-card>
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="Detail Info" name="first" lazy>
            <info :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Status Log" name="statusLog" lazy>
            <status-log :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Blacklist" name="fourth" lazy>
            <aff-blacklist :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Sub Blacklist" name="fifth" lazy>
            <aff-sub-blacklist :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Source Affiliate Blacklist" name="sixth" lazy>
            <source-aff-blacklist :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Source Affiliate Sub Blacklist" name="serventh" lazy>
            <source-aff-sub-blacklist :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Whitelist" name="fourteen" lazy>
            <offer-aff-whitelist :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Sub WhiteList" name="eighth" lazy>
            <offer-aff-sub-white-list :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Source Trackinges" name="offerSourceTrackinges" lazy>
            <offer-source-trackinges :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Track Cap" name="eleventh" lazy>
            <offer-track-cap :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Permit" name="fifteenth" lazy>
            <offer-aff-permit
              :offerId="offerId"
              :affiliateLoading="affiliateLoading"
              :affiliatesGroupList="affiliatesGroupList"
            />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Cap" name="twelfth" lazy>
            <offer-aff-cap :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Affiliate Payout Cap Limit" name="affPayoutCapLimit" lazy>
            <aff-payout-cap-limit :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Event Revenue" name="thirteen" lazy>
            <event-revenue :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Source Offer Clone" name="fifteen" lazy>
            <source-offer-clone :offerId="offerId" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
  import Info from './components/Info';
  import AffBlacklist from './components/AffBlacklist';
  import AffSubBlacklist from './components/AffSubBlacklist';
  import SourceAffBlacklist from './components/SourceAffBlacklist';
  import SourceAffSubBlacklist from './components/SourceAffSubBlacklist';
  import OfferAffSubWhiteList from './components/OfferAffSubWhiteList';
  import OfferTrackCap from './components/OfferTrackCap';
  import OfferAffCap from './components/OfferAffCap.vue';
  import OfferAffPermit from './components/OfferAffPermit.vue';
  import StatusLog from './components/StatusLog.vue';

  import { mapActions, mapState } from 'vuex';
  import EventRevenue from './components/EventRevenue.vue';
  import OfferAffWhitelist from './components/OfferAffWhitelist.vue';
  import SourceOfferClone from './components/SourceOfferClone.vue';
  import AffPayoutCapLimit from './components/AffPayoutCapLimit.vue';
  import OfferSourceTrackinges from './components/OfferSourceTrackinges.vue';

  export default {
    components: {
      OfferSourceTrackinges,
      Info,
      AffBlacklist,
      AffSubBlacklist,
      SourceAffBlacklist,
      SourceAffSubBlacklist,
      OfferAffSubWhiteList,
      OfferTrackCap,
      OfferAffCap,
      OfferAffPermit,
      StatusLog,
      EventRevenue,
      OfferAffWhitelist,
      SourceOfferClone,
      AffPayoutCapLimit,
    },

    data() {
      return {
        offerId: this.$route.query.offerId + '',
        activeName: 'first',
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
    },
  };
</script>
