<template>
  <div>
    <el-table
      :data="sourceBlackList"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column label="AffiliateId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SourceId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="sourceDetailClick(scope.row)">{{
            scope.row.sourceId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SourceName" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="sourceDetailClick(scope.row)">{{
            scope.row.sourceName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Description" prop="description" align="center"></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column label="Operate Time" prop="operateTime" align="center"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="sourcetotal"
      @handleSizeChange="handlesourceAffSubBlackSizeChange"
      @handleCurrentChange="handlesourceAffSubBlackCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import { getSourceAffBlacklist } from 'api/offer/detail';
  let clickTimer = null;

  export default {
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        sourceBlackList: [],
        sourcetotal: null,
        pageSize: 10,
        currentPage: 1,
      };
    },
    components: {
      Pagination,
    },
    mounted() {
      this.getAffOfferSource();
    },
    methods: {
      getAffOfferSource() {
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getSourceAffBlacklist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.sourceBlackList = response.result;
            this.sourcetotal = response.total;
          }
        });
      },
      sourceDetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      handlesourceAffSubBlackSizeChange(val) {
        this.pageSize = val;
        this.handlesourceAffSubBlackCurrentChange(1);
      },
      handlesourceAffSubBlackCurrentChange(val) {
        this.currentPage = val;
        this.getAffOfferSource();
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style></style>
