<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <!-- dialogVisible = true -->
        <el-button type="primary" @click="addSourceCloneClick">Add</el-button>
        <el-button type="primary" @click="batchDelClick">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="sourceCloneList"
      border
      stripe
      style="width:100%;"
      height="80vmin"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="Offer Id" prop="offerId" align="center"></el-table-column>
      <el-table-column
        label="Source Clone"
        prop="sourceOfferCloneId"
        align="center"
      ></el-table-column>
      <el-table-column label="Description" prop="description" align="center"> </el-table-column>
      <el-table-column label="Operator" prop="createBy" align="center"></el-table-column>
      <el-table-column label="Operate Time" prop="createTime" align="center"></el-table-column>
      <!-- <el-table-column label="Option" align="center" min-width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="addSourceCloneClick(scope.row)">Update</el-button>
          <el-button type="danger" @click="delClick(scope.row.id)">Delete</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="sourceCloneTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delDialogVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">cancel</el-button>
        <el-button type="primary" @click="delSourceClone()">confirm</el-button>
      </span>
    </el-dialog>
    <!-- PB 配置模块框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Source Clone"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form
        :model="sourceClone"
        label-position="left"
        label-width="120px"
        ref="sourceCloneForm"
        :rules="rules"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="SourceOfferIds" prop="sourceOfferIds">
              <el-input v-model="sourceClone.sourceOfferIds" placeholder="ps: 1,2,3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Description" prop="description">
              <el-input v-model="sourceClone.description" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSourceClone()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';

  import { getSourceCloneList, addSourceClone, delteSourceClone } from 'api/offer/detail';
  const defaultSourceClone = {
    sourceOfferIds: '',
    description: '',
  };
  export default {
    components: {
      Pagination,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        selectIds: [],
        sourceCloneTotal: null,
        currentPage: 1,
        pageSize: 20,
        delDialogVisible: false,
        sourceCloneList: [],
        rules: {
          sourceOfferIds: [
            {
              required: true,
              message: 'eventName must be specified',
            },
          ],
          description: [
            {
              required: true,
              message: 'revenue must be specified',
            },
          ],
        },
        dialogVisible: false,
        sourceClone: {},
      };
    },
    mounted() {
      this.getSourceCloneList();
    },
    methods: {
      getSourceCloneList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
          offerId: this.offerId,
        };
        getSourceCloneList(param).then((response) => {
          if (response.code === 200) {
            this.sourceCloneList = response.result;
            this.sourceCloneTotal = response.total;
          }
        });
      },
      addSourceCloneClick(row) {
        this.sourceClone = Object.assign({}, defaultSourceClone, row);
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.sourceCloneForm.clearValidate();
        });
      },
      batchDelClick() {
        const length = this.selectIds.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delDialogVisible = true;
      },
      delClick(id) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.delSourceClone(id);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      delSourceClone(id) {
        const ids = id ? id : this.selectIds.join(',');
        const params = {
          ids,
          offerId: this.offerId,
        };
        delteSourceClone(params).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getSourceCloneList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
        this.delDialogVisible = false;
      },
      addSourceClone() {
        this.$refs.sourceCloneForm.validate((valid) => {
          if (valid) {
            let param = {
              ...this.sourceClone,
              offerId: this.offerId,
            };
            param.sourceOfferIds = param.sourceOfferIds.split(',');
            const typeStr = param.id ? 'Update' : 'Add';
            addSourceClone(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: typeStr + ' Success',
                  type: 'success',
                });
                this.getSourceCloneList();
              } else {
                this.$message.error(typeStr + ' Error:' + response.message);
              }
            });
            this.dialogVisible = false;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleSelectionChange(val) {
        this.selectIds = val.map((item) => item.id);
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getSourceCloneList();
      },
    },
  };
</script>

<style></style>
