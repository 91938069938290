<template>
  <div>
    <el-row class="row_line">
      <el-form :model="affiliateSubBlack" label-width="100px" :inline="true">
        <el-col :lg="8" :md="20">
          <el-form-item label="AffiliateId">
            <group-select
              v-model="affiliateSubBlack.affiliateIds"
              :data-source="affiliatesGroupList"
              :loading="affiliateLoading"
              multiple
              clearable
              filterable
              style="width:200px;"
              placeholder="请选择"
            >
              <template #option-item="{scope}">
                <div
                  :style="{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }"
                >
                  <span>{{ scope.label }}</span>
                  <el-tag effect="dark" :hit="true" :type="scope.type" size="mini">{{
                    scope.tag
                  }}</el-tag>
                </div>
              </template>
            </group-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Affiliate Sub">
            <el-input style="width:100px;" v-model="affiliateSubBlack.affSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="7" :md="20">
          <el-form-item label="Description">
            <el-input style="width:140px;" v-model="affiliateSubBlack.description"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="3" :md="20">
          <el-form-item>
            <el-button type="primary" @click="addAffiliateSub">Add</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <el-form :inline="true">
        <el-col :lg="10" :md="20">
          <el-form-item label="Affiliate Id" label-width="100px">
            <el-input v-model="affiliateIds"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="10" :md="20">
          <el-form-item>
            <el-button type="primary" @click="getAffSubOfferBlacklist(1)">Search</el-button>
            <el-button type="primary" @click="delAffiliateSubBlackBatch">Delete</el-button>
            <el-button type="primary" @click="delAffiliateSubBlackClick">DeleteBatch</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-table
      :data="affiliateSubBlackList"
      highlight-current-row
      @selection-change="handleAffiliateSubBlackSelectionChange"
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" min-width="50" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Api Sync" prop="apiSync" align="center">
        <template slot-scope="scope">
          {{ scope.row.apiSync }}
        </template>
      </el-table-column>
      <el-table-column
        label="Affiliate Sub"
        prop="affSub"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="offerAffSubBlacktotal"
      @handleSizeChange="handleofferAffSubBlackSizeChange"
      @handleCurrentChange="handleofferAffSubBlackCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除渠道黑名单模态框 -->
    <el-dialog title="Batch Delete Black Affiliate" width="40%" :visible.sync="blackAffVisible">
      <el-select
        v-model="affiliateIdsArr"
        multiple
        :loading="affiliateLoading"
        clearable
        filterable
        reserve-keyword
        placeholder="请选择"
      >
        <el-option-group
          v-for="group in affiliatesGroupList"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.children"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-option-group>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="blackAffVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAffiliateSubBlack">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import {
    getAffSubBlacklist,
    batchDelAffSubBlacklist,
    deleteAffSubBlacklist,
    addAffBlacklist,
  } from 'api/offer/detail';
  let clickTimer = null;
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        affiliateSubName: null,
        affiliateSubBlack: {},
        affiliateIds: '',
        affiliateSubBlackList: [],
        offerAffSubBlacktotal: null,
        pageSize: 10,
        currentPage: 1,
        affiliateSubBlackSelectionList: [],
        delArr: [],
        affiliateIdsArr: [],
        blackAffVisible: false,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getAffSubOfferBlacklist();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getAffSubOfferBlacklist(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          affiliateIds: this.affiliateIds,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getAffSubBlacklist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.affiliateSubBlackList = response.result;
            this.offerAffSubBlacktotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      addAffiliateSub() {
        const param = {
          ...this.affiliateSubBlack,
        };
        addAffBlacklist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.getAffSubOfferBlacklist();
          } else {
            this.$message.error(response.message);
          }
        });
      },
      delAffiliateSubBlackClick() {
        this.affiliateIdsArr = [];
        this.blackAffVisible = true;
      },
      delAffiliateSubBlack() {
        const affiliateIds = this.affiliateIdsArr.join(',');
        deleteAffSubBlacklist(this.offerId, affiliateIds).then((response) => {
          if (response.code === 200) {
            this.$message.success('Delete Success');
            this.getAffSubOfferBlacklist();
            this.blackAffVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      deleteBlackAff(offerId) {
        const delArrStr = this.delArr.join(',');
        batchDelAffSubBlacklist(offerId, delArrStr).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAffSubOfferBlacklist();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
      },
      delAffiliateSubBlackBatch() {
        const length = this.affiliateSubBlackSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的行呢~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            for (let i = 0; i < length; i++) {
              this.delArr.push(this.affiliateSubBlackSelectionList[i].id);
            }
            this.deleteBlackAff(this.offerId);
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      handleAffiliateSubBlackSelectionChange(val) {
        this.affiliateSubBlackSelectionList = val;
      },
      handleofferAffSubBlackSizeChange(val) {
        this.pageSize = val;
        this.handleofferAffSubBlackCurrentChange(1);
      },
      handleofferAffSubBlackCurrentChange(val) {
        this.currentPage = val;
        this.getAffSubOfferBlacklist();
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
