<template>
  <div>
    <el-table
      :data="offerAffiliateCapList"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column label="AffiliateId">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Daily Cap">
        <template slot-scope="scope">
          <span v-if="scope.row.dailyCap === undefined || scope.row.dailyCap === null">-</span>
          <span v-else>{{ scope.row.dailyCap }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getAffCapList } from 'api/offer/detail';
  let clickTimer = null;
  export default {
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        offerAffiliateCapList: [],
      };
    },
    mounted() {
      this.getOfferAffCap();
    },
    methods: {
      getOfferAffCap() {
        getAffCapList(this.offerId).then((response) => {
          if (response.code === 200) {
            this.offerAffiliateCapList = response.result;
          }
        });
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style></style>
