<template>
  <div>
    <el-row class="row_line">
      <el-form :inline="true">
        <el-col :lg="9" :md="20">
          <el-form-item label="Affiliate Id" label-width="110px">
            <el-input v-model="offerAffSubWhiteId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item>
            <el-button type="primary" @click="getAffSubOfferWhiteList(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-form :inline="true" :model="affiliateSubWhite" label-width="80px">
      <el-form-item label="Type">
        <el-select v-model="affiliateSubWhite.type" style="width:100px;" clearable>
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="AffiliateId">
        <group-select
          v-model="affiliateSubWhite.affiliateIds"
          :data-source="affiliatesGroupList"
          multiple
          :loading="affiliateLoading"
          clearable
          reserve-keyword
          filterable
          style="width:180px;"
          placeholder="请选择"
        >
          <template #option-item="{scope}">
            <div
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }"
            >
              <span>{{ scope.label }}</span>
              <el-tag effect="dark" :hit="true" :type="scope.type" size="mini">{{
                scope.tag
              }}</el-tag>
            </div>
          </template>
        </group-select>
      </el-form-item>
      <el-form-item label="AffSub">
        <el-input style="width:120px;" v-model="affiliateSubWhite.affSub"></el-input>
      </el-form-item>
      <el-form-item label="DailyCap">
        <el-input v-model="affiliateSubWhite.dailyCap"></el-input>
      </el-form-item>
      <el-form-item label="Description" label-width="100px">
        <el-input v-model="affiliateSubWhite.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addAffiliateWhite">Add</el-button>
        <el-button type="primary" @click="delAffiliateSub">Delete Batch</el-button>
        <el-button type="primary" @click="delAffiliateSubBatch">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="affiliateSubWhiteList"
      border
      @selection-change="handleAffiliateSubWhiteSelectionChange"
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" width="90" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Affiliate Name"
        prop="affiliateName"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Type" prop="type" align="center"></el-table-column>
      <el-table-column label="Api Sync" prop="apiSync" align="center">
        <template slot-scope="scope">
          {{ scope.row.apiSync }}
        </template>
      </el-table-column>
      <el-table-column label="affSub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="DailyCap" prop="dailyCap" align="center"></el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column
        label="OperateTime"
        prop="operateTime"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="affiliateSubWhiteTotal"
      @handleSizeChange="handleaffiliateSubWhiteSizeChange"
      @handleCurrentChange="handleaffiliateSubWhiteCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 批量删除模块框 -->
    <el-dialog
      title="Batch Delete Affiliate AffSub"
      width="40%"
      :visible.sync="delBatchAffSubVisible"
    >
      <el-form label-position="left" label-width="100px">
        <el-row>
          <el-col :lg="20" :md="20">
            <el-form-item label="Affiliate">
              <el-select
                v-model="delAffiliateIds"
                :loading="affiliateLoading"
                clearable
                multiple
                reserve-keyword
                placeholder="请选择"
              >
                <el-option-group
                  v-for="group in affiliatesGroupList"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delBatchAffSubVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAffSubClick">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { mapState, mapActions } from 'vuex';
  import {
    getOfferAffSubWhiteList,
    addOfferAffSubWhiteList,
    deleteOfferAffSubWhiteList,
    batchDelOfferAffSubWhiteList,
  } from 'api/offer/detail';
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        offerAffSubWhiteId: null,
        affiliateSubWhite: {},
        typeOptions: [
          {
            label: 'IN-AFF',
            value: 'IN-AFF',
          },
          {
            label: 'IN-ALL',
            value: 'IN-ALL',
          },
        ],
        affiliateSubWhiteList: [],
        affiliateSubWhiteTotal: null,
        affiliateSubWhiteSelectionList: '',
        pageSize: 10,
        currentPage: 1,
        delBatchAffSubVisible: false,
        delAffiliateIds: [],
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffSubOfferWhiteList();
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getAffSubOfferWhiteList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          affiliateId: this.offerAffSubWhiteId,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getOfferAffSubWhiteList(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.affiliateSubWhiteList = response.result;
            this.affiliateSubWhiteTotal = response.total;
          } else {
            this.affiliateSubWhiteList = [];
            this.affiliateSubWhiteTotal = null;
          }
        });
      },
      addAffiliateWhite() {
        const param = {
          ...this.affiliateSubWhite,
        };
        if (param.type == null) {
          this.$message.error('please choose type');
          return;
        }

        if ((param.affiliateIds && param.affiliateIds.length === 0) || !param.affSub) {
          this.$message.error('write first!!!');
          return;
        }
        addOfferAffSubWhiteList(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getAffSubOfferWhiteList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      delAffiliateSubBatch() {
        const length = this.affiliateSubWhiteSelectionList.length;
        if (length <= 0) {
          this.$message.wraning('你还没有选中要删除的数据哦~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            for (let i = 0; i < length; i++) {
              this.delArr.push(this.affiliateSubWhiteSelectionList[i].id);
            }
            batchDelOfferAffSubWhiteList(this.offerId, this.delArr.join(',')).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Delete Success',
                  type: 'success',
                });
                this.getAffSubOfferWhiteList();
              } else {
                this.$message.error('Delete Error:' + response.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
        this.delArr = [];
      },
      delAffiliateSub() {
        this.delBatchAffSubVisible = true;
      },
      delAffSubClick() {
        if (!this.delAffiliateIds || this.delAffiliateIds.length === 0) {
          this.$message.console.warning('要先选择Affiliate哦~');
          return;
        }
        const affiliateIds = this.delAffiliateIds.join(',');
        deleteOfferAffSubWhiteList(this.offerId, affiliateIds).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAffSubOfferWhiteList();
            this.delBatchAffSubVisible = false;
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleaffiliateSubWhiteSizeChange(val) {
        this.pageSize = val;
        this.handleaffiliateSubWhiteCurrentChange(1);
      },
      handleaffiliateSubWhiteCurrentChange(val) {
        this.currentPage = val;
        this.getAffSubOfferWhiteList();
      },
      handleAffiliateSubWhiteSelectionChange(val) {
        this.affiliateSubWhiteSelectionList = val;
      },
    },
  };
</script>

<style></style>
