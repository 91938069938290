<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <!-- dialogVisible = true -->
        <el-button type="primary" @click="changePayoutCapClick">Add</el-button>
        <el-button type="primary" @click="batchDelClick">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="payoutCapList"
      border
      stripe
      style="width:100%;"
      height="80vmin"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="Offer Id"
        prop="offerId"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column
        label="Offer Name"
        prop="offerName"
        align="center"
        min-width="200"
      ></el-table-column>
      <el-table-column
        label="Affiliate Id"
        prop="affiliateId"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column label="Affiliate Name" prop="affiliateName" align="center" min-width="120">
      </el-table-column>
      <el-table-column label="Daily Cap" prop="dailyCap" align="center" min-width="100">
      </el-table-column>
      <el-table-column label="Cap Percent" prop="capPercent" align="center" min-width="110">
      </el-table-column>
      <el-table-column label="Origin Cap" prop="originCap" align="center" min-width="100">
      </el-table-column>
      <el-table-column label="Payout Value" prop="payoutValue" align="center" min-width="110">
      </el-table-column>
      <el-table-column label="description" prop="description" align="center" min-width="100">
      </el-table-column>
      <el-table-column
        label="Operator"
        prop="operator"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        align="center"
        min-width="170"
      ></el-table-column>
      <el-table-column label="Option" align="center" min-width="210">
        <template slot-scope="scope">
          <el-button type="primary" @click="changePayoutCapClick(scope.row)">Update</el-button>
          <el-button type="danger" @click="delClick(scope.row.id)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="payoutCapTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delDialogVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">cancel</el-button>
        <el-button type="primary" @click="delPayoutCap()">confirm</el-button>
      </span>
    </el-dialog>
    <!-- PB 配置模块框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Payout Cap Limit"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="750px"
    >
      <el-form
        :model="payoutCap"
        label-position="left"
        label-width="85px"
        ref="payoutCapForm"
        :rules="rules"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="affiliateId" prop="affiliateId">
              <group-select
                v-model="payoutCap.affiliateId"
                :data-source="affiliatesGroupList"
                reserve-keyword
                :loading="affiliateLoading"
                clearable
                filterable
                placeholder="请选择"
              >
                <template #option-item="{scope}">
                  <div
                    :style="{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }"
                  >
                    <span>{{ scope.label }}</span>
                    <el-tag effect="dark" :hit="true" :type="scope.type" size="mini">{{
                      scope.tag
                    }}</el-tag>
                  </div>
                </template>
              </group-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="payout" prop="payout">
              <el-input v-model="payoutCap.payout" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="dailyCap" prop="dailyCap">
              <el-input v-model="payoutCap.dailyCap" @input="checkDailyCapAndCapPercent" />
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="capPercent" prop="capPercent">
              <el-input v-model="payoutCap.capPercent" @input="checkDailyCapAndCapPercent" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="description" prop="description">
              <el-input v-model="payoutCap.description" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePayoutCap()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';

  import { getAffPayoutCapList, updateAffPayoutCap, deleteAffPayoutCap } from 'api/offer/detail';
  import GroupSelect from '@/components/GroupSelect';
  import { mapState, mapActions } from 'vuex';

  const defaultPayoutCap = {
    affiliateId: '',
    dailyCap: '',
    capPercent: '',
    payout: '',
    description: '',
  };
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      const commonRule = (rule, value, callback) => {
        if (this.payoutCap.dailyCap || this.payoutCap.capPercent) {
          callback();
          return;
        }
        callback(new Error('DailyCap and CapPercent must have a value'));
      };
      return {
        selectIds: [],
        payoutCapTotal: null,
        currentPage: 1,
        pageSize: 20,
        delDialogVisible: false,
        payoutCapList: [],
        rules: {
          affiliateId: [
            {
              required: true,
              message: 'affiliateId must be specified',
            },
          ],
          dailyCap: [
            {
              validator: commonRule,
              trigger: 'blur',
            },
          ],
          capPercent: [
            {
              validator: commonRule,
              trigger: 'blur',
            },
          ],
        },
        dialogVisible: false,
        payoutCap: {},
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getPayoutCapList(1);
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),

      getPayoutCapList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getAffPayoutCapList(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.payoutCapList = response.result;
            this.payoutCapTotal = response.total;
          }
        });
      },
      changePayoutCapClick(row) {
        this.payoutCap = Object.assign({}, defaultPayoutCap, row, { payout: row.payoutValue });
        delete this.payoutCap.payoutValue;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.payoutCapForm.clearValidate();
        });
      },
      batchDelClick() {
        const length = this.selectIds.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delDialogVisible = true;
      },
      delClick(id) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.delPayoutCap(id);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      delPayoutCap(id) {
        const ids = id ? id : this.selectIds.join(',');
        deleteAffPayoutCap(this.offerId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getPayoutCapList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
        this.delDialogVisible = false;
      },
      changePayoutCap() {
        this.$refs.payoutCapForm.validate((valid) => {
          if (valid) {
            let param = {
              ...this.payoutCap,
            };
            const typeStr = param.id ? 'Update' : 'Add';
            updateAffPayoutCap(this.offerId, param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: typeStr + ' Success',
                  type: 'success',
                });
                this.getPayoutCapList();
              } else {
                this.$message.error(typeStr + ' Error:' + response.message);
              }
            });
            this.dialogVisible = false;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleSelectionChange(val) {
        this.selectIds = val.map((item) => item.id);
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getPayoutCapList();
      },
      checkDailyCapAndCapPercent() {
        this.$refs.payoutCapForm.validateField('dailyCap');
        this.$refs.payoutCapForm.validateField('capPercent');
      },
    },
  };
</script>

<style></style>
